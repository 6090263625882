import React from 'react';
import './Title.css'

function Title() {
  return (
    <section id="title">
      <div className="title-content">
        <h2></h2>
        <h1 className="name">Arya Rathee</h1>
        <h2 className="name2"> Software Developer </h2>
        <a href="mailto:ratheearya@gmail.com" id="email-button" className="btn">Get in touch</a>
      </div>
    </section>
  );
}

export default Title;
