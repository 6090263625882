import React from 'react';
import './Contact.css'
import instagram from './images/instagram.png'
import linkedin from './images/linkedin.png'
import facebook from './images/facebook.png'
import github from './images/github.png'

const ConnectWithMe = () => {
  return (
    <div className="connect-with-me" id="contact">
      {/* <h2 style={{marginTop: "40px"}}>Connect with Me</h2> */}
      <div className="social-links">
        <a href="https://www.linkedin.com/in/aryarathee/" target="_blank" rel="noreferrer noopener">
          <img className="social-icon" src={linkedin} alt="LinkedIn" />
        </a>
        <a href="https://github.com/ratheearya" target="_blank" rel="noreferrer noopener">
          <img className="social-icon" src={github} alt="Github" />
        </a>
        <a href="https://www.facebook.com/arya.rathee.5" target="_blank" rel="noreferrer noopener">
          <img className="social-icon" src={facebook} alt="Facebook" />
        </a>
        <a href="https://www.instagram.com/arya.rathee" target="_blank" rel="noreferrer noopener">
          <img className="social-icon" src={instagram} alt="Instagram" />
        </a>
      </div>
    </div>
  );
};

export default ConnectWithMe;
