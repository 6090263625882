import React, { useState } from 'react';
import './Projects.css'; // Import the CSS file
import chatNow from './images/chatNow.png'
import stockTracker from './images/StockTracker.png'
import sudoku from './images/sudoku.png'
import tracking from './images/trackingVisual.gif'

const JourneyItem = ({ image, title, description, link }) => (
  <div className="journey-item">
    <img src={image} alt={title} className="journey-image" />
    <h3 className="journey-title">{title}</h3>
    <p className="journey-description">{description}</p>
    {/* <a href={link} className="journey-link">Learn More</a> */}
  </div>
);

const Projects = () => {
  const journeyData = [
    {
      image: tracking,
      title: 'Movement Tracker',
      description: "One of the more visual programs that I built during my research included a visualization tool for my mentor's point cloud tracking research.",
      link: "https://www.google.com/"
    },
    {
      image: stockTracker,
      title: 'Stock Tracker',
      description: 'A place where users can save stocks that they want to track all on one page. Simply create an account, search for stocks, and save them!',
    },
    {
      image: chatNow,
      title: 'ChatNow',
      description: 'An instant Messaging App built with websockets and Flask for the back-end.',
    },
    {
      image: sudoku,
      title: 'Sudoku Solver',
      description: 'One of my first projects ever where I created an automatic sudoku solver that uses a backtracking algorithm. Still one of my favorites!',
    },
  ];

  const [startIndex, setStartIndex] = useState(0);

  const handleClickPrevious = () => {
    setStartIndex((prevIndex) => prevIndex - 1);
  };

  const handleClickNext = () => {
    setStartIndex((prevIndex) => prevIndex + 1);
  };

  return (
    <section id="projects">
      <div className="projects">
        <h1 className="section-title"> PROJECTS </h1>
        <div className="journey-list">
          {journeyData.slice(startIndex, startIndex + 1).map((item, index) => (
            <JourneyItem
              key={index}
              image={item.image}
              title={item.title}
              description={item.description}
              link={item.link}
            />
          ))}
        </div>
        <div className="carousel-buttons">
          <button
            className="carousel-button"
            onClick={handleClickPrevious}
            disabled={startIndex === 0}
          >
            Previous
          </button>
          <button
            className="carousel-button"
            onClick={handleClickNext}
            disabled={startIndex >= journeyData.length - 1}
          >
            Next
          </button>
        </div>
      </div>
    </section>
  );
};

export default Projects;
