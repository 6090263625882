import React from "react";

const Publications = () =>{
    return(
        <section>
        <div>
            <h1>PUBLICATIONS</h1>
            <ol>
                <li>
                    <a href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=uu2JToYAAAAJ&authuser=1&citation_for_view=uu2JToYAAAAJ:u5HHmVD_uO8C">
                    CLUE: Safe Model-Based RL HVAC Control Using Epistemic Uncertainty Estimation. ACM BuildSys
(2023). Zhiyu An, Xianzhong Ding, Arya Rathee, and Wan Du.
                    </a>
                </li>
            </ol>

        </div>
        </section>
    )
}
export default Publications