import React from 'react';
import './Experience.css'
import Plenful from './images/Plenful.png'
import UCM from './images/UCM.gif'
import UCSC from './images/UCSC.jpg'

const experiences = [
  {
    id: 1,
    photo: UCM,
    title: 'Research Intern',
    date: 'August 2023',
    description: 'Worked as a Machine Learning Research Intern, where I built and implimented custom Deep Reinforcement Learning enivornmnets, and performed various algorithm analytical tasks such as measuring the impactfulness of PPO,SAC,MPC, and MPPI algorithms',
  },
  {
    id: 2,
    photo: Plenful,
    title: 'Software Engineer Intern',
    date: 'April 2023',
    description: 'I worked as a software development intern under a startup called Plenful. This is where I learned the basics of React and Typescript. My project was to build a Graph-UI library for json data.',
  },
  {
    id: 3,
    photo: UCSC,
    title: 'UC Santa Cruz',
    date: 'Class of 2024',
    description: "Bachelor's degree in Computer Science (B.S)",
  },
];

const Experience = () => (
  <div>
    <h1> PAST EXPERIENCES </h1>
  <div className="experience-list" id="experience">
    {experiences.map(experience => (
      <div className="experience" key={experience.id}>
        <img className="experience-image" src={experience.photo} alt={`Experience ${experience.id}`} />
        <div className="experience-details">
          <h3>{experience.title}</h3>
          <h3 className='date'>{experience.date}</h3>
          <p className="experience-description">{experience.description}</p>
        </div>
      </div>
    ))}
  </div>
  </div>
);

export default Experience;
