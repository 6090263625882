import React from 'react';

function Footer() {
  return (
    <footer>
      <a href='google.com'> Access my full Resume HERE! </a>
    </footer>
  );
}

export default Footer;
