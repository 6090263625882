import React from 'react';
import './App.css';
import Header from './Header';
import Title from './Title';
import About from './About';
import Projects from './Projects';
import Contact from './Contact';
import Footer from './Footer';
import Experience from './Experience';
import Publications from './Publications';

function App() {
  return (
    <div>
      {/* <Header /> */}
      <Title />
      <Contact />
      {/* <About /> */}
      <Experience />
      <Projects />
      <Publications />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
